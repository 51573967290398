import React, {FunctionComponent} from 'react';
import {StyledMediaCard} from './MediaCard.styles';
import {Card, CardStyle} from '../Card';
import {VideoPlayer} from '../VideoPlayer';
import {Theme} from '../../theme/Theme';
import {AudioPlayer} from '../AudioPlayer';
import {MediaCardProps, MediaProps, MediaType} from './MediaCard.types';

const getMedia = (
  media: MediaProps,
  height: string,
  testID?: string,
  tabIndex?: number
) => {
  switch (media.type) {
    case MediaType.IMAGE:
      return media.render();
    case MediaType.VIDEO:
      return (
        <VideoPlayer
          {...media}
          height={height}
          testID={`video-${testID}`}
          tabIndex={tabIndex}
        />
      );
    case MediaType.AUDIO:
      return <AudioPlayer {...media} height={height} />;
  }
};

export const MediaCard: FunctionComponent<MediaCardProps> = ({
  testID,
  flexDirection = 'row',
  height = '400px',
  colour,
  media,
  cardStyle,
  centerContent,
  children,
  tabIndex,
}) => (
  <StyledMediaCard
    data-testid={testID}
    height={height}
    flexDirection={flexDirection}
    cardColour={cardStyle === CardStyle.BORDER ? Theme.colours.grey : colour}
    centerContent={centerContent}
  >
    <Card
      height={height}
      colour={colour}
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      cardStyle={cardStyle}
    >
      {children}
    </Card>
    {getMedia(media, height, testID, tabIndex)}
  </StyledMediaCard>
);
