import React, {ButtonHTMLAttributes, FunctionComponent, Fragment} from 'react';
import {LoadingIcon} from '../Icon/LoadingIcon';
import {StyledButton} from './Button.styles';
import {ButtonVariant} from '@focusrite-novation/ampify-web-ui';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** The button variant style*/
  variant?: ButtonVariant;
  /** Css display value */
  display?: 'block' | 'inline-flex';
  /** StyledComponents polymorphic as prop */
  as?: 'div' | 'span' | 'a';
  /** When used will turn the button into an anchor tag*/
  href?: string;
  /** Override button background */
  background?: string;
  /** Used to override onClick typing*/
  onClick?: () => void;
}

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  variant = ButtonVariant.PRIMARY,
  display = 'inline-flex',
  as,
  href,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      variant={variant}
      display={display}
      as={href ? 'a' : as}
      href={href}
    >
      {variant === ButtonVariant.LOADING ? (
        <Fragment>
          <LoadingIcon
            width="24px"
            height="24px"
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
          {children}
        </Fragment>
      ) : (
        children
      )}
    </StyledButton>
  );
};
