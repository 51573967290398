import styled from 'styled-components';
import {Theme} from '../../theme/Theme';
import {StyledMediaCardProps} from './MediaCard.types';

const {breakpoints, space} = Theme;

export const StyledMediaCard = styled.div<StyledMediaCardProps>`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: auto;
  margin: ${space[5]}px 0;

  > * {
    margin: 0;
  }

  @media screen and (min-width: ${breakpoints[1]}px) {
    flex-direction: ${({flexDirection}) => flexDirection};
    height: ${({height}) => (height ? height : 'auto')};

    > * {
      flex: 1 0 0;
      width: 0;
      height: ${({height}) => (height ? height : 'auto')};
      overflow: hidden;
    }
  }

  div {
    iframe {
      height: 100%;
      max-height: 100%;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: ${breakpoints[3]}px) {
    article {
      > div {
        padding-left: ${({centerContent}) => (centerContent ? '15%' : 'auto')};
        padding-right: ${({centerContent}) => (centerContent ? '15%' : '20%')};
      }
    }
  }
`;
