import {css, default as styled} from 'styled-components';
import Theme from '../../theme';
import {ButtonVariant} from './ButtonVariant';

const {colours, fonts, fontSizes, space} = Theme;

export interface ButtonColours {
  background: string;
  color: string;
  hover: string;
  hoverBackground: string;
  hoverBorder: string;
  border: string;
}

const variantStyles: Map<ButtonVariant, ButtonColours> = new Map([
  [
    ButtonVariant.MONOCHROME,
    {
      background: colours.greys[7],
      color: colours.white,
      hover: colours.black,
      hoverBackground: colours.white,
      hoverBorder: colours.black,
      border: `solid 1px ${colours.black}`,
    },
  ],
  [
    ButtonVariant.PRIMARY,
    {
      background: colours.blue,
      color: colours.white,
      hover: colours.white,
      hoverBackground: colours.black,
      hoverBorder: colours.black,
      border: 'none',
    },
  ],
  [
    ButtonVariant.INVERTED,
    {
      background: colours.white,
      color: colours.blue,
      hover: colours.white,
      hoverBackground: colours.black,
      hoverBorder: colours.black,
      border: 'none',
    },
  ],
  [
    ButtonVariant.DARK,
    {
      background: colours.black,
      color: colours.white,
      hover: colours.black,
      hoverBackground: colours.white,
      hoverBorder: colours.white,
      border: 'none',
    },
  ],
  [
    ButtonVariant.YELLOW,
    {
      background: colours.yellow,
      color: colours.black,
      hover: colours.white,
      hoverBackground: colours.black,
      hoverBorder: colours.black,
      border: 'none',
    },
  ],
  [
    ButtonVariant.LOADING,
    {
      background: colours.grey,

      // by setting the colour to transparent it forces the button
      // to keep the same width so it doesn't shift around when
      // moving into the loading state
      color: colours.transparent,
      hover: colours.transparent,
      hoverBackground: colours.grey,
      hoverBorder: colours.grey,
      border: 'none',
    },
  ],
]);

export interface StyledButtonProps {
  /** Css display value */
  display?: 'block' | 'inline-flex';
  /** The button variant style*/
  variant: ButtonVariant;
  /** StyledComponents polymorphic as prop */
  as?: 'div' | 'span' | 'a';
  /** When used will turn the button into an anchor tag*/
  href?: string;
  /** Override button colour */
  background?: string;
}

export const StyledButton = styled.button<StyledButtonProps>(
  ({variant, background, color}) => {
    const variantStyle = variantStyles.get(variant);
    return css`
      position: relative;
      font-family: ${fonts.heading};
      font-size: ${fontSizes[1]}px;
      font-weight: bold;
      padding: ${space[2]}px ${space[4]}px;
      outline: none;
      color: ${color ? color : variantStyle?.color};
      background-color: ${background ? background : variantStyle?.background};
      border: ${variantStyle?.border};
      transition: background-color 0.3s;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: max-content;
      display: flex;
      text-decoration: none;
      min-height: 42px;

      &:focus-visible {
        outline: auto;
      }
      @media (hover: hover) {
        &:hover {
          background-color: ${variantStyle?.hoverBackground};
          color: ${variantStyle?.hover};
          border-color: ${variantStyle?.hoverBorder};

          svg {
            polygon,
            path {
              fill: ${variantStyle?.hover};
            }
          }
        }
      }
    `;
  }
);
