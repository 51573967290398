import {StyledCardProps} from '../Card/Card.styles';
import {AudioPlayerProps} from '../AudioPlayer/AudioPlayer';
import {VideoPlayerProps} from '../VideoPlayer/VideoPlayer';
import {ReactElement} from 'react';

export enum MediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  AUDIO = 'AUDIO',
}

export interface StyledMediaCardProps {
  /** Css flex-direction value */
  flexDirection?: 'row' | 'row-reverse';
  /** the colour used for the cards background */
  cardColour?: string;
  /** Css height value */
  height?: string;
  /** center the content so the padding is even */
  centerContent?: boolean;
}

interface ImageMediaProps {
  type: MediaType.IMAGE;
  render: () => ReactElement;
}

interface AudioMediaProps extends AudioPlayerProps {
  type: MediaType.AUDIO;
}

interface VideoMediaProps extends VideoPlayerProps {
  type: MediaType.VIDEO;
}

export type MediaProps = ImageMediaProps | AudioMediaProps | VideoMediaProps;

export interface MediaCardProps extends StyledCardProps, StyledMediaCardProps {
  media: MediaProps;
  testID?: string;
}
