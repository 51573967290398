import React, {FunctionComponent, ReactElement} from 'react';
import {MediaCard, MediaType} from '../MediaCard';
import {Button} from '../Button';
import {Theme} from '../../theme/Theme';
import {ProductCardContent} from '../../types/HomepageContent';
import {ButtonVariant} from '@focusrite-novation/ampify-web-ui';

interface ProductCardProps extends ProductCardContent {
  testID?: string;
  image: () => ReactElement;
  colour: string;
  flip?: boolean;
}

export const ProductCard: FunctionComponent<ProductCardProps> = ({
  type,
  title,
  body,
  button,
  colour,
  flip,
  image,
  testID,
}) => (
  <MediaCard
    testID={testID}
    flexDirection={flip ? 'row-reverse' : 'row'}
    media={{
      type: MediaType.IMAGE,
      render: image,
    }}
    height="600px"
    colour={colour}
    centerContent
  >
    <h5>{type}</h5>
    <h2>{title}</h2>
    <span style={{paddingBottom: `${Theme.space[4]}px`}}>{body}</span>
    <Button href={button.url} variant={ButtonVariant.DARK}>
      {button.label}
    </Button>
  </MediaCard>
);
