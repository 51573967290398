import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {ProductCard} from '../components/ProductCard';
import {Theme} from '../theme/Theme';
import {HomepageContent} from '../types/HomepageContent';
import {ProductImage} from '../components/ProductImage';
import {navigate} from 'gatsby';
import {FooterContent} from '../types/FooterContent';
import {ButtonVariant} from '../components/Button/ButtonVariant';
import {Button} from '../components/Button';
import BackgroundImage from 'gatsby-background-image';

interface IndexPageProps {
  pageContext: {
    content: HomepageContent;
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
  };
  location: Location;
}

const productCardColours = [
  Theme.colours.yellow,
  Theme.colours.blue,
  Theme.colours.orange,
  Theme.colours.red,
];

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 70vh;
  background-position: bottom;

  @media (max-width: ${Theme.breakpoints[1]}px) {
    ::after {
      background-position: top !important;
    }
  }
`;

const ImageOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at 50vw 75%,
    transparent,
    rgba(8, 10, 10, 0.9)
  );
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: ${Theme.space[9]}px;

  h1 {
    font-size: ${Theme.fontSizes[7]}px;
    line-height: ${Theme.lineHeights.heading[4]}px;
  }

  > div {
    height: 50%;
  }

  @media screen and (min-width: ${Theme.breakpoints[1]}px) {
    h1 {
      font-size: ${Theme.fontSizes[8]}px;
      line-height: ${Theme.lineHeights.heading[5]}px;
    }

    > div {
      height: 100%;
    }
  }
`;

const StyledGutters = styled(Gutters)`
  @media (max-width: ${Theme.breakpoints[1]}px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  height: 100%;
  justify-content: center;
  color: ${Theme.colours.white};

  span {
    padding-bottom: ${Theme.space[4]}px;
  }

  @media screen and (max-width: ${Theme.breakpoints[1]}px) {
    align-items: center;
    justify-content: flex-start;
    margin-top: 10%;

    h1,
    h2,
    span,
    a,
    button {
      text-align: center;
    }
  }
`;

const IndexPage: FunctionComponent<IndexPageProps> = ({
  pageContext: {
    content: pageContent,
    contentfulFooterContent,
    customerPortalBaseUrl,
  },
}) => {
  const content = pageContent.carousel[0];

  return (
    <Layout
      {...contentfulFooterContent}
      customerPortalBaseUrl={customerPortalBaseUrl}
    >
      <SEO
        title={content.title}
        meta={[
          {
            property: `og:title`,
            content: content.title,
          },
          {
            property: 'twitter:title',
            content: content.title,
          },
        ]}
      />
      <StyledBackgroundImage fluid={content.background}>
        <ImageOverlay />
        <Content>
          <StyledGutters maxWidth={GutterMaxWidth.LARGE} paddingSides>
            <ContentColumn data-testid="hero">
              <h1>{content.title}</h1>
              <span>{content.body}</span>
              {content.button.label && content.button.url && (
                <Button
                  variant={ButtonVariant.DARK}
                  background={Theme.colours.blue}
                  href={content.button.url}
                >
                  {content.button.label}
                </Button>
              )}
            </ContentColumn>
          </StyledGutters>
        </Content>
      </StyledBackgroundImage>
      <Gutters maxWidth={GutterMaxWidth.LARGE}>
        {pageContent.productCards.map(({image, ...card}, index) => (
          <ProductCard
            key={`product-${index}`}
            testID={`product-${index}`}
            {...card}
            colour={productCardColours[index % productCardColours.length]}
            flip={!(index % 2)}
            image={() => (
              <ProductImage
                {...image}
                onClick={() => navigate(card.button.url)}
              />
            )}
          />
        ))}
      </Gutters>
    </Layout>
  );
};

export default IndexPage;
