import React, {CSSProperties, FunctionComponent} from 'react';
import {Theme} from '../../theme/Theme';
import styled, {css, keyframes} from 'styled-components';

const {colours} = Theme;

interface AnimatedPathProps {
  duration: number;
  delay: number;
  colour: string;
  startColour: string;
  start: number;
}

const animatedPathLoop = ({
  duration,
  delay,
  colour,
  startColour,
  start,
}: AnimatedPathProps) => {
  const animation = keyframes`
    from {
      transform:rotate(${start}deg);
      fill:${startColour};
      stroke:${startColour};
    }
    30% {
     fill:${colour};
     stroke:${colour};
    }
    70% {
     fill:${colour};
     stroke:${colour};
    }
    to {
      transform:rotate(360deg);
      fill:${startColour};
      stroke:${startColour};
    }
  `;
  return css`
    animation: ${animation} ${duration}ms ease-in-out infinite;
    animation-delay: ${delay}ms;
  `;
};

const AnimatedPath = styled.path<AnimatedPathProps>(
  (props) => css`
    fill: ${props.startColour};
    stroke: ${props.startColour};
    stroke-width: 4px;
    transform-origin: 329px 328px;
    ${animatedPathLoop(props)};
  `
);

interface LoadingIconProps {
  height?: string;
  width?: string;
  style?: CSSProperties;
}

export const LoadingIcon: FunctionComponent<LoadingIconProps> = ({
  style,
  height = '70px',
  width = '70px',
}) => (
  <svg
    height={height}
    width={width}
    fill={colours.black}
    viewBox="0 0 657 657"
    style={style}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M328.5,0 C350.8,0 373,2.2 394.6,6.7 C415.7,11 436.4,17.5 456.2,25.9 C475.7,34.1 494.4,44.3 512,56.2 C528.902857,67.6628571 544.862041,80.7299592 559.327524,95.0346216 L560.6,96.3 L560.231211,96.6692497 C619.511687,156.130143 656.2,238.118207 656.2,328.5 C656.2,418.9125 619.502367,500.885042 560.231544,560.330451 L560.6,560.7 C545.7,575.6 529.4,589.1 512,600.8 C494.4,612.7 475.7,622.9 456.2,631.1 C436.4,639.5 415.6,645.9 394.6,650.3 C373,654.7 350.7,657 328.5,657 L327.7,657 L327.7,657 C306.002703,657 284.400073,654.917312 263.352656,650.659827 L261.6,650.3 C240.5,646 219.8,639.5 200,631.1 C180.5,622.9 161.8,612.7 144.2,600.8 C127.297143,589.337143 111.337959,576.270041 96.8724758,561.965378 L95.6,560.7 L95.9687888,560.33075 C36.6883134,500.869857 0,418.881793 0,328.5 C0,238.086944 36.698084,156.11395 95.9695491,96.668452 L95.6,96.3 C110.5,81.4 126.8,67.9 144.2,56.2 C161.8,44.3 180.5,34.1 200,25.9 C219.8,17.5 240.6,11.1 261.6,6.7 C283.2,2.3 305.5,0 327.7,0 L328.5,0 L328.5,0 Z M328.3,159.7 C235.2,159.7 159.4,235.4 159.4,328.6 C159.4,390.608265 192.909135,444.870033 242.786885,474.244783 C249.049099,477.891209 255.558383,481.144105 262.2,484 C283,492.8 305.1,497.3 327.9,497.3 C421,497.3 496.8,421.6 496.8,328.4 C496.8,266.391735 463.290865,212.129967 413.413115,182.755217 C407.150901,179.108791 400.641617,175.855895 394,173 C373.2,164.2 351.1,159.7 328.3,159.7 Z"
        fill={colours.transparent}
        fillRule="nonzero"
      />
      <AnimatedPath
        start={-620}
        duration={1500}
        delay={0}
        d="M505.5,219.3 C497,205.6 486.9,192.8 475.3,181.2 L447.3,209.2 C477.9,239.8 496.8,282 496.8,328.5 C496.8,375 477.9,417.3 447.3,447.8 L447.3,447.8 L475.5,476 C513.3,438.2 536.7,386 536.7,328.5 C536.7,288.5 525.4,251.1 505.8,219.3 L505.7,219.4 L505.5,219.3 Z"
        colour={colours.yellow}
        startColour={colours.transparent}
        fillRule="nonzero"
      />
      <AnimatedPath
        start={-580}
        duration={1500}
        delay={300}
        d="M539.7,198.5 L505.7,219.4 C525.3,251.2 536.6,288.6 536.6,328.6 C536.6,386.1 513.2,438.3 475.4,476.1 L475.4,476.1 L475.4,476.1 C466,485.5 455.7,494 444.7,501.4 C444.6,501.5 444.4,501.6 444.3,501.7 C443,502.6 441.8,503.4 440.5,504.2 C440.3,504.3 440.2,504.4 440,504.5 C439.1,505.1 438.2,505.6 437.4,506.2 L458.1,540 C472.7,531 486.4,520.4 498.9,508.5 C511.4,496.6 522.7,483.4 532.5,469.3 L532.6,469.1 L532.8,469.2 L532.8,469.2 C560.4,429.1 576.6,380.6 576.6,328.4 C576.6,280.9 563.1,236.4 539.7,198.5 Z"
        colour={colours.orange}
        startColour={colours.transparent}
        fillRule="nonzero"
      />
      <AnimatedPath
        start={-440}
        duration={1500}
        delay={600}
        d="M527,119.9 C526.3,119.2 525.6,118.6 524.8,117.9 C523.9,117.1 523,116.2 522.2,115.4 C521,114.3 519.8,113.3 518.6,112.2 C518.2,111.8 517.8,111.4 517.3,111.1 C515.9,109.8 514.4,108.6 512.9,107.4 C512.7,107.2 512.5,107.1 512.3,106.9 C510.7,105.6 509.1,104.3 507.5,103 C507.4,102.9 507.3,102.9 507.3,102.8 C505.6,101.5 504,100.2 502.3,98.9 C502.3,98.9 502.2,98.8 502.2,98.8 C500.5,97.5 498.8,96.2 497,95 C497,95 496.9,94.9 496.9,94.9 C495.2,93.7 493.5,92.4 491.7,91.2 C491.7,91.2 491.6,91.1 491.6,91.1 L469.1,123.8 C483.2,133.6 496.4,144.9 508.3,157.3 C520.2,169.8 530.8,183.6 539.9,198.2 L540,198.4 L539.8,198.5 C563.1,236.4 576.6,280.9 576.6,328.6 C576.6,380.8 560.4,429.3 532.8,469.4 L565.6,492 C597.6,445.5 616.4,389.2 616.4,328.6 C616.4,246.6 582.1,172.5 527,119.9 Z"
        colour={colours.red}
        startColour={colours.transparent}
        fillRule="nonzero"
      />
      <AnimatedPath
        start={-300}
        duration={1500}
        delay={900}
        d="M560.2,96 L532,124.2 C584.2,176.5 616.6,248.6 616.6,328.1 C616.6,388.7 597.8,445 565.7,491.5 L565.8,491.6 L565.7,491.8 C555.8,506.1 544.5,519.7 532,532.1 L560.1,560.2 C619.6,500.7 656.4,418.6 656.4,328.1 C656.4,237.6 619.6,155.5 560.2,96 Z"
        colour={colours.blue}
        startColour={colours.transparent}
        fillRule="nonzero"
      />
    </g>
  </svg>
);
